.snowflakes {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    overflow: hidden;
    z-index: -1;
    background-color: #ececec !important;
  }
  
  .snowflake {
    position: absolute;
    top: -10%;
    color: #fff;
    background-color: #fff;
    border-radius: 50%;
    width: 0.3em;
    height: 0.3em;
    animation: fall linear infinite;
    opacity: 0.8;
  }
  
  @keyframes fall {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(110vh);
    }
  }

  .snowdrift {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 0px; 
    background: #fff;
    box-shadow: inset -10px 0 14px 0 #ececec;
    border-top-left-radius: 50% 20px; 
    border-top-right-radius: 50% 20px; 
    animation: grow 50s forwards;
  }

  @keyframes grow {
    0% {
      height: 0;
    }
    30%{
        height: 0;
    }
    100% {
      height: 50px;
    }
  }

  .vetka{
    position: fixed;
    z-index: 103;
    top: 0;
    width: 180px;
    height: 129px;
    background-image: url('../../public/assets/media/vetka.png');
    background-size: cover;

    @media (max-width: 1600px){
        width: 129px;
        height: 89px;
    }

    @media (max-width: 1430px){
        width: 69px;
        height: 49px;
    }

    @media (max-width: 992px){
      display: none;
    }

    &.left{
        left: 0;
    }

    &.right{
        right: 0;
        transform: scaleX(-1);
    }
  }

  .heartflakes {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    overflow: hidden;
    z-index: -1;
  }

  .heartflake{
    z-index: 100;
    position: absolute;
    top: -10%;
    background: linear-gradient(to bottom right, red, #f56c95);
    color: red;
    width: 10px;
    height: 10px;
    opacity: 1;
    clip-path: path('M8.524,0.268c-1.296,-0.576-2.948,0-3.524,1.224C4.424,0.196,2.7,-0.38,1.476,0.268C0.112,0.916,-0.536,2.496,0.54,4.436 C1.332,5.804,2.7,6.812,5,8.68c2.372,-1.868,3.74,-2.876,4.46,-4.244C10.536,2.568,9.888,0.916,8.524,0.268z');
    animation: fall linear infinite;
  }

  .cupid{
    position: fixed;
    z-index: 103;
    top: -25px;
    width: 170px;
    height: 170px;
    background-image: url('../../public/assets/media/cupid.png');
    background-size: cover;

    @media (max-width: 1600px){
        width: 150px;
        height: 150px;
    }

    @media (max-width: 1430px){
        width: 100px;
        height: 100px;
    }

    @media (max-width: 992px){
        display: none;
    }

    &.left{
        left: -40px;
    }

    &.right{
        right: -40px;
        transform: scaleX(-1);

        @media (max-width: 1430px){
          display: none;
        }
    }
  }
